import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getExchangeRate, postExchangeRate, updateExchangeRate, removeExchangeRate, getHistory } from './action';
import { showPopup, showToast, convEmptyStringToCustom, removeNullObject } from '../../helpers';
import ExchangeRateDatatable from './datatable';
import ExchangeRateModal from './modal';

class ExchangeRatePage extends Component {

    constructor(props) {
        super(props);
        this.EMPTY_FORM = {
            id: null,
            from: '',
            to: '',
            amount: '',
        };

        this.state = {
            openModal: false,
            formData: this.EMPTY_FORM,
            formType: null,
            alert: 'hidden',
        };
    }

    UNSAFE_componentWillMount() {
        this.fetchDatatable();
    }

    componentDidUpdate(prevProps) {
        if(this.props.success && this.props.success !== prevProps.success) {
            let message;
            if(this.props.success === 'create') message = 'Data successfully created';
            if(this.props.success === 'update') message = 'Data successfully updated';
            if(this.props.success === 'delete') message = 'Data successfully deleted';
            this.setState({openModal: false});
            this.fetchDatatable();
            showToast('success', message);
        }
    }

    fetchDatatable = (limit, page, filter) => {
        this.props.getExchangeRate({limit, page, filter});
    }

    toggleModal = (type, formData) => {
        this.setState({
            formData: type !== 'create' ? formData : this.EMPTY_FORM,
            formType: type,
            openModal: !this.state.openModal,
        });

        if(type === 'detail') {
            this.props.getHistory({rate_id : formData.id});
        }
    }

    toggleConfirmDelete = (id) => {
        showPopup('confirm-delete', id, this.handleDelete);
    }

    handleSubmit = (params, {setErrors, resetForm}) => {
        const { formData, formType } = this.state;
        let convertedParams = convEmptyStringToCustom(params, null);
                              removeNullObject(convertedParams);

        if(formType === 'create') {
            this.props.postExchangeRate(convertedParams, setErrors, resetForm);
        } else if(formType === 'update' && formData.id !== null) {
            showPopup(
                'confirm-update',
                convertedParams,
                () => this.props.updateExchangeRate(convertedParams, setErrors, resetForm)
            );
        }
    }

    handleDelete = (id) => {
        this.props.removeExchangeRate({id});
    }

    render () {
        const dummyCurrencyData = [
            { id: 1, code: 'EUR', description: 'Euro' },
            { id: 2, code: 'IDR', description: 'Rupiah' },
            { id: 3, code: 'SGD', description: 'Singapore Dollar' },
            { id: 4, code: 'USD', description: 'US Dollar' }
        ];
        const { openModal, formData, formType } = this.state;
        const { datatable, currencies, historyData, modalLoading, dtLoading, error, errorMessage } = this.props;
        return (
            <div className="page-container">
                <ExchangeRateDatatable
                    isLoading={dtLoading}
                    dataSource={datatable}
                    currencyData={dummyCurrencyData}
                    toggleModal={this.toggleModal}
                    toggleConfirmDelete={this.toggleConfirmDelete}
                    reloadDatatable={this.fetchDatatable}
                />
                <ExchangeRateModal
                    isOpen={openModal}
                    currencyData={dummyCurrencyData}
                    formData={formData}
                    formType={formType}
                    historyData={historyData}
                    handleSubmit={this.handleSubmit}
                    handleClose={() => this.toggleModal('close')}
                    errorMessage={error !== 'validate' && errorMessage ? errorMessage : undefined}
                    modalLoading={modalLoading}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ auth, exchangeRate, currency }) => ({
    auth,
    exchangeRate,
    passwordExpiredDate: auth.passwordExpiredDate,
    datatable: exchangeRate.datatable,
    historyData: exchangeRate.historyData,
    success: exchangeRate.success,
    error: exchangeRate.error,
    errorMessage: exchangeRate.errorMessage,
    dtLoading: exchangeRate.dtLoading,
    modalLoading: exchangeRate.modalLoading,
    currencies: currency.datatable,
});

const mapDispatchToProps = dispatch => ({
    getExchangeRate: (params) => dispatch(getExchangeRate(params)),
    getHistory: (params) => dispatch(getHistory(params)),
    postExchangeRate: (params, setErrors) => dispatch(postExchangeRate(params, setErrors)),
    updateExchangeRate: (params, setErrors) => dispatch(updateExchangeRate(params, setErrors)),
    removeExchangeRate: (params) => dispatch(removeExchangeRate(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExchangeRatePage);


