import React, { Component } from 'react';
import { Datatable } from '../../components/datatables';
import { thousand, dateFormatStd, getMonthFromDate, getYearFromDate } from '../../helpers';

class HistoryDatatable extends Component {

    // replaceIdToText = (id) => {
    //     const currency = this.props.currencyData.find(curr => curr.id === id)
    //     return currency.name;
    // }
    replaceIdToText = (id) => {
        const currency = this.props.currencyData.find(curr => curr.id === id);
        return currency ? currency.code : 'Unknown Currency';
    }

    renderTableBody = (dataSource) => {
        if(Array.isArray(dataSource.data) && dataSource.data.length) {
            return dataSource.data.map((value, index) => {
                const { id, amount, updated_at, rate, month  } = value;
                return (
                    <tr key={id}>
                        <td className="index-td">{index+1}</td>
                        <td className="text-center">{month ? getMonthFromDate(month) +' - '+ getYearFromDate(month) : '-'}</td>
                        <td className="text-center">{rate ? rate.from.code : '-'}</td>
                        <td className="text-center">{rate ? rate.to.code : '-'}</td>
                        <td className="text-right">{thousand(amount)}</td>
                        <td className="text-center">{dateFormatStd(updated_at, 'DMYSTRING')}</td>
                    </tr>
                );
            });
        }
    }

    render() {
        const { dataSource, isLoading } = this.props;
        const datatableHeader = [
            {label: 'Month-Year', field: 'month'},
            {label: 'From', field: 'from_id'},
            {label: 'To', field: 'to_id'},
            {label: 'Rate History', field: 'rate_amount'},
            {label: 'Created', field: 'last_updated'},
        ];

        return (
            <Datatable
                isLoading={isLoading}
                tableHeader={datatableHeader}
                tableBody={this.renderTableBody}
                dataSource={dataSource}
                tableClassCustom={"table table-striped dataTable"}
                pagination={true}
                sortable={false}
                searchable={false}
                limitable={false}
                creatable={false}
                card={false}
                showLoaderText={false}
                loaderSize={'xs'}
                loaderCustom={'exchange-history-loader'}
            />
        );
    }
}

export default HistoryDatatable;