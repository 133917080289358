import React, { Component   } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { showPopup } from '../../helpers';
import { Select } from '../../components/forms';
import { InlineLabel, Button } from '../../components/ui';
import { MInput } from '../../components/material/form';
import { Datepicker } from '../../components/forms';
import Modal from '../../components/modal';
import HistoryDatatable from './datatable-history';

class ExchangeRateModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            editing: false,
            dataChanged: false,
            additionalData: [],
            dynamicData: {},
        };
        this.formik = React.createRef();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(!nextProps.isOpen && this.props.isOpen && this.props.isOpen !== nextProps.isOpen) {
            this.setState({ dataChanged : false });
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.formData !== prevProps.formData) {
            this.setState({ data : this.props.formData });
        }
    }

    render() {
        const { 
            isOpen, formType, formData, errorMessage, handleSubmit, handleClose, modalLoading, 
            currencyData, historyData,
        } = this.props;
    
        const validation = Yup.object({
            from: Yup.string().required('Please select Base Currency'),
            to: Yup.string().required('Please select Target Currency'),
            amount: Yup.string().required('Please fill in rate amount'),
            month: Yup.date().required('Please choose month'),
        });

        const replaceIdToText = (id) => {
            const currency = currencyData.find(curr => curr.id === id)
            // return currency.name;
            return currency ? currency.code : 'Unknown Currency';
        }
    
        const renderDetail = () => {
            return (
                <div>
                    <div className="row">
                        <div className="col-lg-12">
                            <InlineLabel
                                customLabel={"col-lg-3 col-md-3 form-control-label"}
                                customInput={"col-lg-9 col-md-9"}
                                align={"text-left"}
                                label={"Currency"}
                                text={ replaceIdToText(formData.from) +' to '+ replaceIdToText(formData.to)}
                            />      
                            <InlineLabel
                                customLabel={"col-lg-3 col-md-3 form-control-label"}
                                customInput={"col-lg-9 col-md-9"}
                                align={"text-left"}
                                label={"Current Rate"}
                                text={formData.amount}  
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <HistoryDatatable
                                isLoading={modalLoading}
                                dataSource={historyData}
                            />
                        </div>
                    </div>
                </div>
            )
        }
    
        const renderForm = () => {
            return (
                <div>
                    <div className="row clearfix">
                        <div className='col-md-12 col-sm-12'>
                            <Select
                                label="From"
                                className="form-control"
                                name="from"
                                placeholder="Select base currency"
                                data={currencyData}
                                keyPair={['id', 'code']}
                                mandatory={formType === 'update' ? false : true}
                                line={formType === 'update' ? false : true}
                                disabled={formType === 'update' ? true : false}
                            />
                            <Select
                                label="To"
                                className="form-control"
                                name="to"
                                placeholder="Select target currency"
                                data={currencyData}
                                keyPair={['id', 'code']}
                                mandatory={formType === 'update' ? false : true}
                                line={formType === 'update' ? false : true}
                                disabled={formType === 'update' ? true : false}
                            />
                            <Datepicker
                                className={'datepicker-exchange'}
                                name={'month'}
                                label={'Month'}
                                placeholder="Select month"
                                icon={'date_range'}
                                type={'monthyear'}
                                setValue={undefined}
                            />
                            <MInput
                                label="Amount"
                                name="amount"
                                placeholder="0.00"
                                type="currency"
                                maxDigit={20}
                                required
                                fullWidth
                            />
                        </div>
                    </div>
                </div>
            );
        }

        const handleCancelAndClose = () => {
            let { dirty, resetForm } = this.formik.current;
            const clearAndClose = () => {
                dirty = false;
                handleClose();
                resetForm();
            }
            
            if(dirty || this.state.dataChanged) {
                showPopup(
                    'custom-confirm',
                    "You haven't saved the data. Cancel the proceed anyway? Unsaved data may occurred",
                    'Discard changes',
                    'warning',
                    'reversed',
                    () => clearAndClose()
                )
            } else {
                clearAndClose();
            }
        }
                
        const clickSubmit = () => {
            const formikProps = this.formik.current;
            formikProps.handleSubmit();
        }

        const getTitle = () => {
            if(formType === 'create') {
                return "Create New Exchange Rate";
            } else if (formType === 'update') {
                return "Update  Exchange Rate";
            } else {
                return "Exchange Rate History";
            }
        }

        return (
            <Modal
                isOpen={isOpen}
                background={'bg-blue'}
                size={'modal-lg'}
                title={getTitle()}
                onClose={handleCancelAndClose}
                errorMessage={errorMessage}
                content={(
                    <Formik
                        innerRef={this.formik}
                        initialValues={formData}
                        validationSchema={validation}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {() => (
                            <Form autoComplete="off">
                            {
                                formType === 'detail' ?
                                renderDetail() :
                                renderForm()
                            }
                            </Form>
                        )}
                    </Formik>
                )}
                footer={(
                    <div>
                        {
                            formType === 'detail' ?
                            '' :
                            (
                                <Button
                                    classCustom={'btn-lg'}
                                    color={'bg-blue'}
                                    type={'submit'}
                                    disabled={modalLoading}
                                    loading={modalLoading}
                                    text={ formType === 'create' ? 'Save' : 'Update' }
                                    icon={'check'}
                                    onClick={clickSubmit}
                                />
                            )
                        }
                        <button type="button"
                            onClick={()=>{handleCancelAndClose()}}
                            className="btn btn-link waves-effect"
                        >
                            {formType === 'detail' ? 'Close' : "Cancel"}
                        </button>
                    </div>
                )} 
            />
        );
    }
}

export default ExchangeRateModal;